import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormGroup, ButtonGroup } from 'react-bootstrap';
import Spinner from '../../components/Spinner'
import { useParams } from "react-router-dom";
import CustomModal from '../../components/CustomModal';
import ViolationDetails from '../../components/ViolationDetails';
import DefaultLayoutInner from '../../components/DefaultLayoutInner';
import { getPrimaryColor } from '../../Tools';

const moment = require('moment')

moment.fn.fromNow = function (a) {
  var duration = moment().diff(this, 'days');
  return -duration;
}

const Violations = (props) => {

  let {vehicleId} = useParams();
  let {clientString} = useParams();
  const [violations, setViolations] = useState(null)
  const [selectedViolation, setSelectedViolation] = useState(null)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [detailsTab, setDetailsTab] = useState('photos')
  const [client, setClient] = useState(null)
  const [logo, setLogo] = useState('')

  useEffect(() => {
    if (clientString) {
      props.getClientFromStringFunction(clientString)
    } else {
      props.clearClient()
    }
    props.getViolationsByVehicleFunction(vehicleId)
  }, [])

  useEffect(() => {
    setClient(props.client)
  }, [props.client])

  useEffect(() => {
    setViolations(props.violations?.filter(violation => violation.status === 'unpaid'))
  }, [props.violations])

  const getTotalToPay = (violation) => {
    let applyEarlyBird = violation.early_bird_expiry && moment(violation.early_bird_expiry).isAfter(moment()) && (!violation.dispute || (violation.dispute?.status !== 'adjusted' && violation.dispute?.status !== 'accepted'))
    let applyPenalty = violation.penalty_start_at && moment(violation.penalty_start_at).isBefore(moment())

    let totalToPay = violation.total
    if (applyEarlyBird) {
      totalToPay = violation.early_bird_total
    }
    if (applyPenalty) {
      totalToPay = violation.penalty_total
    }
    return totalToPay
  }

  const getDisputeStatus = (violation) => {
    switch (violation.dispute.status) {
      case "open":
        return "OPEN"
      case "rejected":
        return "REJECTED"  
      case "accepted":
        return "ACCEPTED"
      case "adjusted":
        return "BALANCE ADJUSTED"  
    }
  }

  const renderViolations = () => {
    if (props.loadingViolations) {
      return (
        <div style={{
          width: '100%',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Spinner color={getPrimaryColor(client)} size="30px" />
        </div>
      )
    }
    if (props.loadedViolations && violations?.length === 0) {
      return (
        <Row style={{height: 40, marginTop: 12, marginBottom: 8}}>
          <Col style={{textAlign: 'center'}}>
            This citation is closed, no action is needed
          </Col>
        </Row>
      )
    }
    return violations?.map(violation => {
      let showEarlyBird = violation.early_bird_expiry && moment(violation.early_bird_expiry).isAfter(moment()) && (!violation.dispute || (violation.dispute?.status !== 'adjusted' && violation.dispute?.status !== 'accepted'))
      let showPenalty = violation.penalty_start_at && moment(violation.penalty_start_at).isAfter(moment())
      return (
        <div key={violation.violation_number} style={{border: '1px solid #F1F4F7', width: '100%', paddingLeft: 12, paddingRight: 12, paddingBottom: 16, marginBottom: 20}}>
          <Row style={{height: 40, marginTop: 12, marginBottom: 8}}>
            <Col xs={7} style={{paddingTop: 8}}>
              <span style={{fontSize: 18, fontWeight: 'bold'}}>Outstanding balance</span>
            </Col>
            <Col xs={5} className='text-right'>
              <div style={{height: 40, borderRadius: 5, backgroundColor: 'black', width: 120, marginRight: 0, marginLeft: 'auto', textAlign: 'center', paddingTop: 2}}>
                <span style={{fontSize: 24, color: 'white', fontWeight: 'bold'}}>${parseFloat(getTotalToPay(violation)).toFixed(2)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row style={{marginTop: 6}}>
                <Col>
                  <span style={{fontSize: 16}}>Details:</span>
                </Col>
              </Row>
              <Row style={{marginTop: 2}}>
                <Col><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.violation_number}</span></Col>
              </Row>
              <Row style={{marginTop: 2}}>
                <Col><span style={{fontSize: 16, fontWeight: 'bold'}}>{moment(violation.created_at).format('M/D/Y h:mm a')}</span></Col>
              </Row>
              <Row style={{marginTop: 2}}>
                <Col><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.location_name}</span></Col>
              </Row>
              <Row style={{marginTop: 2}}>
                <Col style={{paddingRight: 2}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.license_plate}</span></Col>
              </Row>
            </Col>
            <Col>
              <Row style={{marginTop: 6}}>
                <Col>
                  <span style={{fontSize: 16}}>Violations:</span>
                </Col>
              </Row>

              {violation.violations.map((item, index) => (
                <Row key={index} style={{marginBottom: 8}}>
                  <Col>
                    <span style={{fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>{item.name}</span><br />
                    {item.code?.length > 0 && (
                      <>
                        <span style={{fontSize: 12, fontWeight: 'bold', textAlign: 'left'}}>({item.code})</span><br />
                      </>
                    )}
                    <span style={{fontSize: 16, fontWeight: 'bold', textAlign: 'right'}}>{item?.amount > 0 ? '$' + parseFloat(item.amount).toFixed(2) : 'WARNING'}</span>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          
          {(!!violation.car_make || !!violation.car_type || !!violation.car_color) && (
            <Row style={{marginTop: 12}}>
              {violation.car_make && (
                <Col style={{textAlign: 'left'}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.car_make}</span></Col>
              )}
              {violation.car_type && (
                <Col style={{textAlign: 'left'}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.car_type}</span></Col>
              )}
              {violation.car_color && (
                <Col style={{textAlign: 'left'}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.car_color}</span></Col>
              )}
            </Row>
          )}
          {violation.dispute?.status && (
            <Row style={{marginTop: 12}}>
              <Col style={{textAlign: 'left'}}><span style={{fontSize: 16}}>Dispute status: <span style={{fontSize: 16, fontWeight: 'bold'}}>{getDisputeStatus(violation)}</span></span></Col>
            </Row>
          )}
          {showEarlyBird && (
            <div className="alert alert-danger" role="alert" style={{paddingTop: 8, paddingBottom: 8, marginTop: 8}}>
              <span style={{fontSize: 14, lineHeight: 1, color: 'red'}}>You have {moment(violation.early_bird_expiry).fromNow()} days remaining to pay the discounted fee. After {moment(violation.early_bird_expiry).format('M/D/Y')} the balance will rise to ${parseFloat(violation.total).toFixed(2)}</span>
            </div>
          )}
          {showPenalty && !showEarlyBird && (
            <div className="alert alert-danger" role="alert" style={{paddingTop: 8, paddingBottom: 8, marginTop: 8}}>
              <span style={{fontSize: 14, lineHeight: 1, color: 'red'}}>You have {moment(violation.penalty_start_at).fromNow(true)} days remaining to pay the discounted fee. After {moment(violation.penalty_start_at).format('M/D/Y')} the balance will rise to ${parseFloat(violation.penalty_total).toFixed(2)}</span>
            </div>
          )}
          <Row style={{marginTop: 8}}>
            <Col style={{paddingLeft: 12, paddingRight: 2, textAlign: 'left'}} className='text-left'>
              <Button
                className='btn btn-outline-secondary'
                style={{backgroundColor: 'white', fontSize: 16, width: 125, paddingLeft: 0, paddingRight: 0, marginRight: 'auto'}}
                onClick={() => {setSelectedViolation(violation); setDetailsTab('photos'); setShowDetailsModal(true)}}>
                  View photos
              </Button>
            </Col>
            <Col style={{paddingLeft: 2, paddingRight: 12, textAlign: 'right'}} className='text-right'>
              <Button
                className='btn btn-outline-secondary'
                style={{backgroundColor: 'white', fontSize: 16, width: 125, paddingLeft: 0, paddingRight: 0, marginLeft: 'auto'}}
                onClick={() => {setSelectedViolation(violation); setDetailsTab('dispute'); setShowDetailsModal(true)}}>
                  Dispute
              </Button>
            </Col>
          </Row>
          <Row style={{marginTop: 12}}>
            <Col style={{paddingLeft: 12, paddingRight: 12}} className='text-center'>
              <Button
                className='btn btn-primary'
                style={{color: 'white', fontSize: 16, width: '100%', paddingLeft: 0, paddingRight: 0, fontWeight: 'bold', backgroundColor: getPrimaryColor(client), borderColor: getPrimaryColor(client)}}
                onClick={() => {setSelectedViolation(violation); setDetailsTab('pay'); setShowDetailsModal(true)}}>
                  Pay now
              </Button>
            </Col>
          </Row>
        </div>
      )
    })
  }

  if (props.loadingClient) {
    return (
      <div style={{
        width: '100%',
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>        
        <Spinner color={getPrimaryColor(client)} size="30px" />
      </div>      
    )
  }

  return (
    <DefaultLayoutInner client={client}>
      <Row style={{minHeight: 500}}>
        <Col>
          {renderViolations()}
        </Col>
      </Row>
      <CustomModal show={showDetailsModal}>
        <ViolationDetails
          violation={selectedViolation}
          detailsTab={detailsTab}
          onClose={() => {setShowDetailsModal(false); setSelectedViolation(null)}}
          onPayment={props.payViolationFunction}
          submitDispute={props.submitDisputeFunction}
          submittingDispute={props.submittingDispute}
          submittedDispute={props.submittedDispute}
          submitDisputeFailed={props.submitDisputeFailed}
          getClientLocation={props.getClientLocation}
          client={client}
        />
      </CustomModal>
    </DefaultLayoutInner>
  )
  
}

export default Violations;
