import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid'
import Spinner from '../../components/Spinner'
import QRCode from "react-qr-code";

const moment = require('moment')

const Citation = (props) => {

  document.title = 'Parking violation';

  let {violationNumber} = useParams();
  const [violation, setViolation] = useState(null)
  const [applyEarlyBird, setApplyEarlyBird] = useState(false)
  const [loadedImageCount, setLoadedImageCount] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(false)

  useEffect(() => {
    props.getViolationByViolationNumberFunction(violationNumber)
  }, [violationNumber])

  useEffect(() => {
    if (props.violation?.id) {
      setViolation(props.violation)
      setApplyEarlyBird(props.violation.early_bird_expiry && moment(props.violation.early_bird_expiry).isAfter(moment()))
    }
  }, [props.violation])

  const imageLoaded = () => {
    if (loadedImageCount + 1 === violation.images?.length) {
      setImagesLoaded(true)
    } else {
      setLoadedImageCount(loadedImageCount + 1)
    }
  }

  const getTitle = () => {
    if (violation?.ticket_type === 'warning') {
      if (violation?.warning_term && violation.warning_term.length > 0) {
        return violation.warning_term
      }
    }
    if (violation?.citation_term && violation.citation_term.length > 0) {
      return violation.citation_term
    }
    return 'Citation'
  }

  const getFirstParagraph = () => {
    if (violation?.first_paragraph && violation.first_paragraph.length > 0) {
      return violation.first_paragraph
    }
    return 'Your vehicle was found to be in violation of the terms of parking at ' + violation.location_name + ', ' + violation.location_address1 + ', ' + violation.location_city + ', ' + violation.location_state + ' ' + violation.location_zip
  }

  const getFooterText = () => {
    if (violation?.footer_text && violation.footer_text.length > 0) {
      return violation.footer_text
    }
    return ''
  }

  const getClientString = () => {
    if (violation?.url_string && violation?.url_string?.length > 0) {
      return violation.url_string
    }
    return 'violations'
  }
  
  if (violation?.id) {
    return (
      <>
        <Row style={{height: 60, backgroundColor: 'black', paddingTop: 8}}>
          <Col className='text-center'>
            <span style={{fontSize: 30, color: 'white', fontWeight: 'bold'}}>{getTitle().toUpperCase()}</span>
          </Col>
        </Row>
        <Row style={{height: 50, paddingTop: 8}}>
          <Col className='text-center'>
            <span style={{fontSize: 18, color: 'black', fontWeight: 'bold'}}>Your violation number: {violationNumber}</span>
          </Col>
        </Row>
        <Row style={{paddingTop: 8}}>
          <Col className='text-left'>
            <span style={{fontSize: 16, color: 'black'}}>{getFirstParagraph()}</span>
            <br /><br />
            {(violation.early_bird_total > 0 || violation.penalty_total > 0) && (
              violation.early_bird_total > 0 ? (
                <span style={{fontSize: 16, color: 'black'}}>Please pay or dispute this penalty within {violation.early_bird_days} days to avoid further charges or collections.</span>
              ) : (
                <span style={{fontSize: 16, color: 'black'}}>Please pay or dispute this penalty within {violation.penalty_days} days to avoid further charges or collections.</span>
              )
            )}
          </Col>
        </Row>
        <Row style={{paddingTop: 24}}>
          <Col style={{paddingRight: 30}}>
            {violation.violations.map((item, index) => (
              <Row key={index} style={{height: 40, marginTop: 16}}>
                <Col style={{paddingTop: 8}}>
                  <span style={{fontSize: 18, fontWeight: 'bold'}}>{item.name}</span><br />
                  {item.code?.length > 0 && (
                    <span style={{fontSize: 12}}>{item.code}</span>
                  )}
                </Col>
                <Col className='text-right'>
                  <div style={{height: 40, borderRadius: 5, backgroundColor: 'black', width: 120, marginRight: 0, marginLeft: 'auto', textAlign: 'center', paddingTop: 2}}>
                    <span style={{fontSize: 24, color: 'white', fontWeight: 'bold'}}>{item?.amount > 0 ? '$' + parseFloat(item.amount).toFixed(2) : 'WARNING'}</span>
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row style={{paddingTop: 24}}>
          <Col>
            <div style={{border: '2px solid black', width: '100%', padding: 16}}>
              <Row style={{marginBottom: 8}}>
                <Col style={{paddingTop: 8, textAlign: 'right'}}>
                  <span style={{fontSize: 16, color: 'black'}}>License plate:</span>
                </Col>
                <Col>
                  <span style={{fontSize: 24, color: 'black', fontWeight: 'bold'}}>{violation.license_plate}</span>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  <span style={{fontSize: 18, color: 'black'}}>{moment(violation.created_at).format('h:mm a M/D/Y')}</span>
                </Col>
              </Row>
              {violation.images && violation.images.length > 0 && (
                <>
                  <Row style={{marginTop: 16, marginBottom: 16}}>
                    {violation.images[0] && (
                      <>
                        <Col style={{width: 150, display: imagesLoaded ? 'block' : 'none'}}>
                          <div style={{height: 105, width: 150, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={violation.images[0].url} style={{width: '100%', height: '100%', objectFit: 'cover'}} onLoad={() => imageLoaded()} />
                          </div>
                        </Col>
                        <Col style={{width: 150, display: imagesLoaded ? 'none' : 'block'}}>
                        <div style={{
                            width: '100%',
                            height: 135,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <Spinner color="black" size="30px" />
                        </div>
                        </Col>
                      </>
                    )}
                    {violation.images[1] && (
                      <>
                        <Col style={{width: 150, display: imagesLoaded ? 'block' : 'none'}}>
                          <div style={{height: 105, width: 150, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={violation.images[1].url} style={{width: '100%', height: '100%', objectFit: 'cover'}} onLoad={() => imageLoaded()} />
                          </div>
                        </Col>
                        <Col style={{width: 150, display: imagesLoaded ? 'none' : 'block'}}>
                        <div style={{
                            width: '100%',
                            height: 135,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <Spinner color="black" size="30px" />
                        </div>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row style={{marginTop: 16, marginBottom: 16}}>
                    {violation.images[2] && (
                      <>
                        <Col style={{width: 150, display: imagesLoaded ? 'block' : 'none'}}>
                          <div style={{height: 105, width: 150, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={violation.images[2].url} style={{width: '100%', height: '100%', objectFit: 'cover'}} onLoad={() => imageLoaded()} />
                          </div>
                        </Col>
                        <Col style={{width: 150, display: imagesLoaded ? 'none' : 'block'}}>
                        <div style={{
                            width: '100%',
                            height: 135,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <Spinner color="black" size="30px" />
                        </div>
                        </Col>
                      </>
                    )}
                    {violation.images[3] && (
                      <>
                        <Col style={{width: 150, display: imagesLoaded ? 'block' : 'none'}}>
                          <div style={{height: 105, width: 150, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={violation.images[3].url} style={{width: '100%', height: '100%', objectFit: 'cover'}} onLoad={() => imageLoaded()} />
                          </div>
                        </Col>
                        <Col style={{width: 150, display: imagesLoaded ? 'none' : 'block'}}>
                          <div style={{width: '100%', textAlign: 'center', height: 105, paddingTop: 30}}>
                            <Spinner color="black" size="30px" />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              )}
              {(!!violation.car_make || !!violation.car_type || !!violation.car_color) && (
                <Row style={{marginTop: 24}}>
                  {violation.car_make && (
                    <Col style={{textAlign: 'center'}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.car_make}</span></Col>
                  )}
                  {violation.car_type && (
                    <Col style={{textAlign: 'center'}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.car_type}</span></Col>
                  )}
                  {violation.car_color && (
                    <Col style={{textAlign: 'center'}}><span style={{fontSize: 16, fontWeight: 'bold'}}>{violation.car_color}</span></Col>
                  )}
                </Row>
              )}
            </div>
            <Row style={{marginTop: 24}}>
              <Col className='text-center'>
                {(violation.early_bird_total > 0 || violation.penalty_total > 0) && (
                  violation.early_bird_total > 0 ? (
                    <span style={{fontSize: 18, color: 'black', fontWeight: 'bold'}}>SCAN TO PAY OR DISPUTE<br />WITHIN {violation.early_bird_days} DAYS</span>
                  ) : (
                    <span style={{fontSize: 18, color: 'black', fontWeight: 'bold'}}>SCAN TO PAY OR DISPUTE<br />WITHIN {violation.penalty_days} DAYS</span>
                  )
                )}
              </Col>
            </Row>
            <Row style={{marginTop: 30}}>
              <Col xs={3} style={{textAlign: 'right', paddingTop: 50}}>
                <ArrowRightIcon style={{fontSize: 70}} color={'black'} />
              </Col>
              <Col>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={'https://pay.enforceplus.com/' + getClientString() + '/' + violation.vehicle}
                  viewBox={`0 0 256 256`}
                  />
              </Col>
              <Col xs={3} style={{textAlign: 'left', paddingTop: 50}}>
                <ArrowLeftIcon style={{fontSize: 70}} color={'black'} />
              </Col>
            </Row>
            <Row style={{marginTop: 50}}>
              <Col className='text-center'>
                <span style={{fontSize: 18, color: 'black', fontWeight: 'bold'}}>You can also visit<br />pay.enforceplus.com{getClientString() !== 'violations' ? '/' + getClientString() : ''}</span>
              </Col>
            </Row>
            <Row style={{paddingTop: 30}}>
              <Col>
                <div style={{border: '1px solid black', padding: 16, margin: 24, borderRadius: 7}}>
                  <Row>
                    <Col>
                      <span style={{fontSize: 16, color: 'black', fontWeight: 'bold'}}>Please note:</span>
                      <br /><br />
                      <span style={{fontSize: 16, color: 'black'}}>To pay or dispute as well as receive more information regarding your further charges you may also visit pay.enforceplus.com{getClientString() !== 'violations' ? '/' + getClientString() : ''}.</span>
                      <br /><br />
                      <span style={{fontSize: 16, color: 'black'}}>High resolution photos, including time/date and location data stamps are also available to view.</span>
                      <br /><br />
                      <span style={{fontSize: 16, color: 'black'}}>We reserve the right to pursue or refer this debt through to collections agencies. Parking enforcement provided on behalf of {violation.client_name}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row style={{paddingTop: 30, marginBottom: 100}}>
              <Col>
                <span style={{fontSize: 16, color: 'black'}}>{getFooterText()}</span>
              </Col>
            </Row>  
          </Col>
        </Row>
      </>
    )
  } else {
    return (
      <Row>
        <Col style={{width: 320, display: imagesLoaded ? 'none' : 'block'}}>
        <div style={{
              width: '100%',
              height: 320,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Spinner color="black" size="30px" />
          </div>
        </Col>
      </Row>
    )
  }
  
}

export default Citation;
