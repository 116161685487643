import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import DefaultLayoutInner from '../../components/DefaultLayoutInner';
import { getPrimaryColor, getLogo } from '../../Tools';

const moment = require('moment')

const PaymentSuccess = (props) => {
  let {violationNumber} = useParams();
  const [violation, setViolation] = useState(null)
  const [client] = useState(props.client)

  useEffect(() => {
    props.getViolationByViolationNumberFunction(violationNumber)
  }, [violationNumber])

  useEffect(() => {
    if (props.violation?.id) {
      setViolation(props.violation)
    }
  }, [props.violation])

  return (
    <DefaultLayoutInner client={client}>
      <Row style={{minHeight: 500}}>
        <Col>
          {props.loadingViolation && (
            <div style={{
              width: '100%',
              height: 135,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Spinner color={getPrimaryColor(props.client)} size="30px" />
            </div>
          )}
          {props.loadedViolation && violation?.id && (
            <>
              <Row style={{marginTop: 24}}>
                <Col className='text-center'>
                  <span style={{fontSize: 20, fontWeight: 'bold'}}>Thank you!</span>
                </Col>
              </Row>
              <Row style={{marginTop: 24}}>
                <Col className='text-center'>
                  <span style={{color: getPrimaryColor(client), fontWeight: 'bold'}}>Your payment has been made successfully</span>
                </Col>
              </Row>
              <Row style={{marginTop: 30}}>
                <Col xs={3}>
                  <span>Reference:</span>
                </Col>
                <Col>
                  <span style={{fontWeight: 'bold'}}>{violation.violation_number}-{violation.payment?.reference}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <span>Paid on:</span>
                </Col>
                <Col>
                  <span style={{fontWeight: 'bold'}}>{moment(violation.payment_on).format('M/D/Y h:mm a')}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <span>Card:</span>
                </Col>
                <Col>
                  <span style={{fontWeight: 'bold'}}>{violation.payment?.card_type} {violation.payment?.card_last_four}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <span>Amount:</span>
                </Col>
                <Col>
                  <span style={{fontWeight: 'bold'}}>${parseFloat(violation.payment?.amount).toFixed(2)}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <span>Receipt:</span>
                </Col>
                <Col>
                  <span style={{fontWeight: 'bold'}}><a href={violation.payment?.receipt_url} target='_blank' style={{color: getPrimaryColor(client)}}>Click to view</a></span>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </DefaultLayoutInner>
  )
}

export default PaymentSuccess;
