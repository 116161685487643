import React from 'react';
import styles from './spinner.module.css';

const Spinner = ({ color = '#000', size = '40px' }) => (
  <div 
    className={`${styles.spinner}`}
    style={{
      borderColor: color,
      borderTopColor: '#eff4f9',
      width: size,
      height: size
    }}
  />
);

export default Spinner;