import React, { useEffect, useState } from 'react';
import { Form, Button, FormGroup } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import DefaultLayoutInner from '../../components/DefaultLayoutInner';
import { getPrimaryColor } from '../../Tools';
import { US_STATES, CA_STATES } from '../../constants';

const Main = (props) => {

  let {clientString} = useParams();
  const [violationNumber, setViolationNumber] = useState('')
  const [licensePlate, setLicensePlate] = useState('')
  const [licensePlateState, setLicensePlateState] = useState('')
  const [violationNumberTouched, setViolationNumberTouched] = useState(false)
  const [licensePlateTouched, setLicensePlateTouched] = useState(false)
  const [licensePlateStateTouched, setLicensePlateStateTouched] = useState(false)
  const [violationNumberValid, setViolationNumberValid] = useState(false)
  const [licensePlateValid, setLicensePlateValid] = useState(false)
  const [licensePlateStateValid, setLicensePlateStateValid] = useState(false)

  useEffect(() => {
    props.clearViolationData()
    if (clientString) {
      props.getClientFromStringFunction(clientString)
    }
  }, [])

  useEffect(() => {
    if (violationNumber.length === 12) {
      setViolationNumberValid(true)
    } else {
      setViolationNumberValid(false)
    }
  }, [violationNumber])

  useEffect(() => {
    if (licensePlate.length > 3) {
      setLicensePlateValid(true)
    } else {
      setLicensePlateValid(false)
    }
  }, [licensePlate])

  useEffect(() => {
    if (licensePlateState !== '') {
      setLicensePlateStateValid(true)
    } else {
      setLicensePlateStateValid(false)
    }
  }, [licensePlateState])

  const maskViolationNumberInput = (value) => {
    if ((value.length === 4 || value.length === 9) && (violationNumber.length === 3 || violationNumber.length === 8)) {
      setViolationNumber(value + '-')
    } else {
      setViolationNumber(value)
    } 
  }

  const shouldBeEnabled = () => {
    if (violationNumberValid) {
      return true
    }
    if ((licensePlateStateTouched && licensePlateStateValid) && (licensePlateTouched && licensePlateValid)) {
      return true
    }
    return false
  }

  if (props.loadingClient) {
    return (
      <div style={{
        width: '100%',
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Spinner color={getPrimaryColor(props.client)} size="30px" />
      </div>
    )
  }

  return (
    <DefaultLayoutInner client={props.client}>
      <Form style={{paddingTop: 16}}>
        {props.loadingViolationsFailed && (
          <div className="alert alert-danger" role="alert">
            No record found, please check your violation number or try another license plate &amp; state
          </div>
        )}
        <FormGroup style={{marginBottom: 24}}>
          <label for='violationNumber' style={{color: '#C5C7CA'}}>Violation number</label>
          <input type='text' className={violationNumberTouched ? violationNumberValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='violationNumber' onBlur={() => setViolationNumberTouched(violationNumber.length > 0)} placeholder='____-___-__' value={violationNumber} onChange={(event) => maskViolationNumberInput(event.target.value)}/>
          {!violationNumberValid && violationNumberTouched && (
            <div className="invalid-feedback">
              Please enter your 12 digit Violation number in format xxxx-xxxx-xx.
            </div>
          )}
        </FormGroup>
        <FormGroup style={{marginBottom: 24}}>
          <label for='licensePlate' style={{color: '#C5C7CA'}}>License plate</label>
          <input type='text' className={licensePlateTouched ? licensePlateValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='licensePlate' onBlur={() => setLicensePlateTouched(licensePlate.length > 0)} placeholder='Enter plate' value={licensePlate} onChange={(event) => setLicensePlate(event.target.value.toUpperCase().replace(/\s/gim, ''))}/>
          {!licensePlateValid && licensePlateTouched && (
            <div className="invalid-feedback">
              Please enter your license plate.
            </div>
          )}
        </FormGroup>
        <FormGroup style={{marginBottom: 48}}>
          <label for='licensePlateState' style={{color: '#C5C7CA'}}>State</label>
          <select className={licensePlateStateTouched ? licensePlateStateValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='licensePlateState' onBlur={() => setLicensePlateStateTouched(licensePlateState.length > 0)} onChange={(event) => setLicensePlateState(event.target.value)} value={licensePlateState}>
            <option value={''}>Please select State</option>
            {US_STATES.map((state, idx) => (
              <option key={idx} value={state.abbreviation}>{state.name}</option>
            ))}
            {CA_STATES.map((state, idx) => (
              <option key={idx} value={state.abbreviation}>CA - {state.name}</option>
            ))}            
          </select>
          {!licensePlateStateValid && licensePlateStateTouched && (
            <div className="invalid-feedback">
              Please select your license State.
            </div>
          )}
        </FormGroup>
        <FormGroup style={{marginBottom: 24, textAlign: 'center'}}>
          <Button
            className='btn btn-lg'
            style={{color: 'white', fontSize: 18, backgroundColor: getPrimaryColor(props.client), borderColor: getPrimaryColor(props.client)}}
            onClick={() => props.getViolationBySearchFunction(violationNumber, licensePlate, licensePlateState)}
            disabled={!shouldBeEnabled()}>SEARCH NOW</Button>
        </FormGroup>
      </Form>
    </DefaultLayoutInner>
  )
  
}

export default Main;
