import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { staticTranslate } from '../Tools';

const CustomModal = ({show, onHide, children, title}) => {

  const renderModal = () => {
    return (
      <div style={{height: window.innerHeight - 10}}>
        <Modal.Body style={{backgroundColor: 'white', flex: 1, marginBottom: -1, borderBottom: 'none', height: '100%', paddingTop: 0, paddingLeft: 30, paddingRight: 30, borderTopLeftRadius: 50, borderTopRightRadius: 50, overflow: 'hidden'}}>
          <Row>
            <Col>
              {children}
            </Col>
          </Row>
          
        </Modal.Body> 
      </div>
    )
  }
  
  return (
    <Modal show={show} fullscreen={true} onHide={onHide} animation={false} style={{top: 10}}>
      {renderModal()}     
    </Modal>
  )
}

export default CustomModal;