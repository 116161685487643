import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormGroup } from 'react-bootstrap';
import {
  PaymentForm,
  CreditCard,
  ApplePay,
  GooglePay,
} from 'react-square-web-payments-sdk';
import Spinner from './Spinner'
import { getPrimaryColor } from '../Tools';

const moment = require('moment')

const ViolationDetails = (props) => {

  const [violation, setViolation] = useState(props.violation)
  const [detailsTab, setDetailsTab] = useState(props.detailsTab)
  const [disputeAcknowledged, setDisputeAcknowledged] = useState(false)
  const [name, setName] = useState('')
  const [nameTouched, setNameTouched] = useState(false)
  const [nameValid, setNameValid] = useState(false)
  const [email, setEmail] = useState('')
  const [emailTouched, setEmailTouched] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [mobile, setMobile] = useState('')
  const [mobileTouched, setMobileTouched] = useState(false)
  const [mobileValid, setMobileValid] = useState(false)
  const [reason, setReason] = useState('')
  const [loadedImageCount, setLoadedImageCount] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [evidence1, setEvidence1] = useState(null)
  const [evidence2, setEvidence2] = useState(null)
  const [disputeSubmitted, setDisputeSubmitted] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [client] = useState(props.client)
  const [locationId, setLocationId] = useState(null)

  let applyEarlyBird = violation.early_bird_expiry && moment(violation.early_bird_expiry).isAfter(moment()) && (!violation.dispute || (violation.dispute?.status !== 'adjusted' && violation.dispute?.status !== 'accepted'))
  let applyPenalty = violation.penalty_start_at && moment(violation.penalty_start_at).isBefore(moment())

  const applicationId = 'sq0idp-zmDVkLzJCMFWTbszExs3Og'; // PROD
  // const applicationId = 'sandbox-sq0idb-TpcvnyVjdAI2Ayn2MuLG5A'; // SANDBOX

  useEffect(() => {
    props.getClientLocation(violation.client, violation.location, ['square_location_selected', 'square_locations']).then(locationData => {
      if (locationData.square_location_selected) {
        setLocationId(locationData.square_location_selected)
      } else {
        setLocationId('LC39HE0XC4N09') // PROD
        // setLocationId('L7JDPFMGJRV2N') // SANDBOX          
      }
    })
  }, [])
  

  const height = window.innerHeight

  const getTotalToPay = () => {
    let totalToPay = violation.total
    if (applyEarlyBird) {
      totalToPay = violation.early_bird_total
    }
    if (applyPenalty) {
      totalToPay = violation?.dispute?.adjusted_discount ? parseFloat(violation?.dispute.original_amount) - parseFloat(violation?.dispute?.adjusted_discount) : parseFloat(violation.penalty_total)
    }
    return parseFloat(totalToPay)
  }

  const getServiceFee = () => {
    let service_fee = parseFloat(violation.service_fee)
    if (getTotalToPay()/100*5 > service_fee) {
      service_fee = parseFloat(getTotalToPay()/100*5)
    }
    return parseFloat(service_fee)
  }

  const paymentRequestOptions = {
    requestShippingAddress: false,
    requestBillingInfo: true,
    currencyCode: "USD",
    countryCode: "US",
    total: {
      label: "EnforcePlus",
      amount: parseFloat(getTotalToPay() + parseFloat(getServiceFee())).toFixed(2),
      pending: false
    },
    lineItems: [
      {
        label: "Subtotal",
        amount: parseFloat(getTotalToPay()).toFixed(2),
        pending: false
      },
      {
        label: "Service fee",
        amount: parseFloat(getServiceFee()).toFixed(2),
        pending: false
      }
    ]
  }

  useEffect(() => {
    if (name.length > 3) {
      setNameValid(true)
    } else {
      setNameValid(false)
    }
  }, [name])

  useEffect(() => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
    }
  }, [email])

  useEffect(() => {
    if (mobile.length === 10) {
      setMobileValid(true)
    } else {
      setMobileValid(false)
    }
  }, [mobile])

  useEffect(() => {
    setDisputeAcknowledged(false)
    setDisputeSubmitted(false)
  }, [detailsTab])

  useEffect(() => {
    setDisputeSubmitted(props.submittedDispute)
  }, [props.submittedDispute])

  const getCitationTerm = () => {
    if (violation?.citation_term && violation.citation_term.length > 0) {
      return violation.citation_term
    }
    return 'Citation'
  }

  const getTitle = () => {
    switch (detailsTab) {
      case "photos":
        return 'View photos'
      case "dispute":
        return 'Dispute ' + getCitationTerm().toLowerCase()
      case "pay":
        return 'Pay ' + getCitationTerm().toLowerCase()
    }
  }

  const imageLoaded = () => {
    if (loadedImageCount + 1 === violation.images?.length) {
      setImagesLoaded(true)
    } else {
      setLoadedImageCount(loadedImageCount + 1)
    }
  }

  return (
    <>
      <div style={{height: 50, width: '100%', borderTopLeftRadius: 50, borderTopRightRadius: 50, paddingTop: 16, textAlign: 'center'}}>
        <span style={{fontSize: 24, fontWeight: 'bold'}}>{getTitle()}</span>
      </div>
      <Row style={{marginTop: 16, marginBottom: 8}}>
        <Col style={{paddingLeft: 2, paddingRight: 2}} className='text-center'>
          <Button
            className='btn btn-outline-secondary'
            style={{backgroundColor: 'white', fontSize: 14, width: 125, paddingLeft: 0, paddingRight: 0}}
            onClick={() => detailsTab === 'photos' ? props.onClose() : setDetailsTab('photos')}>
              {detailsTab === 'photos' ? 'Close' : 'View photos'}
          </Button>
        </Col>
        <Col style={{paddingLeft: 2, paddingRight: 2}} className='text-center'>
          <Button
            className='btn btn-outline-secondary'
            style={{backgroundColor: 'white', fontSize: 14, width: 125, paddingLeft: 0, paddingRight: 0}}
            onClick={() => detailsTab === 'dispute' ? props.onClose() : setDetailsTab('dispute') }>
              {detailsTab === 'dispute' ? 'Close' : 'Dispute'}
          </Button>
        </Col>
      </Row>
      <Row style={{marginTop: 12, marginBottom: 16}}>
        <Col style={{paddingLeft: 26, paddingRight: 26}} className='text-center'>
          <Button
            className='btn btn-primary'
            style={{color: 'white', fontSize: 14, width: '100%', paddingLeft: 0, paddingRight: 0, backgroundColor: getPrimaryColor(client), borderColor: getPrimaryColor(client)}}
            onClick={() => detailsTab === 'pay' ? props.onClose() : setDetailsTab('pay')}>
              {detailsTab === 'pay' ? 'Close' : 'Pay now'}
          </Button>
        </Col>
      </Row>
      {detailsTab === 'photos' && (
        <div style={{paddingBottom: 50, height: height - 150, overflowX: 'hidden'}}>
          {(!violation.images || violation.images?.length === 0) && (
            <Row style={{marginBottom: 16}} key={'image-row-0'}>
              <Col className='text-center'>
                No images found
              </Col>
            </Row>
          )}
          {violation.images?.map((image, index) => (
            <Row style={{marginBottom: 16}} key={'image-row-' + index}>
              <Col style={{width: 320, display: imagesLoaded ? 'block' : 'none'}}>
                <div style={{height: 220, width: 320, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto'}}>
                  <img src={image.url} style={{width: '100%', height: '100%', objectFit: 'cover'}} onLoad={() => imageLoaded()} />
                </div>
                <div style={{width: 320, marginLeft: 'auto', marginRight: 'auto'}}>
                  <span>{moment(image.created_at).format('M/D/Y h:mm a')}</span>
                </div>
              </Col>
              <Col style={{width: 320, display: imagesLoaded ? 'none' : 'block'}}>
                <div style={{
                  width: '100%',
                  height: 240,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Spinner color={getPrimaryColor(client)} size="30px" />
                </div>
              </Col>
            </Row>
          ))}
        </div>
      )}
      {detailsTab === 'dispute' && violation.dispute && (
        <div style={{height: height - 150, overflowX: 'hidden', paddingBottom: 50}}>
          <Row>
            <Col style={{paddingTop: 16}}>
              {violation.dispute.status === 'open' && (
                <span>Your dispute has been received and is being reviewed.</span>
              )}
              {violation.dispute.status === 'adjusted' && (
                <span>Your dispute has been reviewed and your balance has been adjusted accordingly.</span>
              )}
              {violation.dispute.status === 'rejected' && (
                <span>Your dispute has been received and reviewed and unfortunately it has been rejected. Please pay the outstanding balance as soon as possible to avoid further penalty.</span>
              )}
              {violation.dispute.status === 'accepted' && (
                <span>Your dispute has been received and reviewed and was accepted. There is nothing to pay.</span>
              )}
            </Col>
          </Row>
        </div>
      )}
      {detailsTab === 'dispute' && !violation.dispute && (
        !disputeSubmitted ? (
          <div style={{height: height - 150, overflowX: 'hidden', paddingBottom: 50}}>
            {!disputeAcknowledged && (
              <Row>
                <Col style={{paddingTop: 16}}>
                  <div className="alert alert-danger" role="alert" style={{paddingTop: 8, paddingBottom: 8}}>
                    <span style={{fontSize: 14, lineHeight: 1, color: 'red'}}>First read this:<br />
                    <br />
                    Common reasons that DO NOT support dismissing a {getCitationTerm().toLowerCase()} are:<br /><br/>
                    I was only in violation for a minute;<br/>
                    I did not know that I could not park there;<br />
                    I think the penalty is too high;<br />
                    I did not see the sign or curb markings;<br />
                    I cannot afford to pay the fine;<br />
                    I have never had a ticket before;<br />
                    I will never do it again;<br />
                    I couldn't pay<br /></span>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div className="form-check form-check-inline">
                  <label className="form-check-label" for="inlineCheckbox1">I acknowledge and understand</label>
                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onClick={(event) => setDisputeAcknowledged(event.target.checked)} style={{backgroundColor: 'grey'}} />   
                </div>
              </Col>
            </Row>
            {!disputeAcknowledged && (
              <Row style={{marginTop: 16, marginBottom: 80}}>
                <Col>
                  <span onClick={() => setDetailsTab('pay')}>Or tap here to pay {getCitationTerm().toLowerCase()}</span> 
                </Col>
              </Row>
            )}
            {disputeAcknowledged && (
              <Form style={{marginTop: 16, marginBottom: 80}}>
                <FormGroup style={{marginBottom: 16}}>
                  <label for='name' style={{color: '#C5C7CA'}}>Name *</label>
                  <input type='text' className={nameTouched ? nameValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='name' onBlur={() => setNameTouched(true)} placeholder='Enter your name' value={name} onChange={(event) => setName(event.target.value)}/>
                  {!nameValid && nameTouched && (
                    <div className="invalid-feedback">
                      Please enter your name.
                    </div>
                  )}
                </FormGroup>
                <FormGroup style={{marginBottom: 16}}>
                  <label for='email' style={{color: '#C5C7CA'}}>Email address *</label>
                  <input type='email' className={emailTouched ? emailValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='email' onBlur={() => setEmailTouched(true)} placeholder='Enter your email' value={email} onChange={(event) => setEmail(event.target.value)}/>
                  {!emailValid && emailTouched && (
                    <div className="invalid-feedback">
                      Please enter a valid email address.
                    </div>
                  )}
                </FormGroup>
                <FormGroup style={{marginBottom: 16}}>
                  <label for='mobile' style={{color: '#C5C7CA'}}>Mobile *</label>
                  <input type='mobile' className={mobileTouched ? mobileValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='mobile' onBlur={() => setMobileTouched(true)} placeholder='Enter your mobile' value={mobile} onChange={(event) => setMobile(event.target.value?.replace(/\D/gim, ''))}/>
                  {!mobileValid && mobileTouched && (
                    <div className="invalid-feedback">
                      Please enter a valid mobile.
                    </div>
                  )}
                </FormGroup>
                <FormGroup style={{marginBottom: 16}}>
                  <label for='reason' style={{color: '#C5C7CA'}}>Reason your {getCitationTerm().toLowerCase()} should be dismissed</label>
                  <textarea style={{height: 100}} className={'form-control'} id='name' placeholder='Enter your reasoning' value={reason} onChange={(event) => setReason(event.target.value)}/>
                </FormGroup>
                <Form.Group controlId="evidence1" style={{marginBottom: 16}}>
                  <Form.Label style={{color: '#C5C7CA'}}>Upload supporting evidence (optional)</Form.Label>
                  <Form.Control type="file" onChange={(event) => setEvidence1(event.target.files[0])}/>
                </Form.Group>
                <Form.Group controlId="evidence2" style={{marginBottom: 16}}>
                  <Form.Label style={{color: '#C5C7CA'}}>Upload supporting evidence (optional)</Form.Label>
                  <Form.Control type="file" onChange={(event) => setEvidence2(event.target.files[0])}/>
                </Form.Group>
                <FormGroup style={{marginBottom: 20, textAlign: 'center'}}>
                  {props.submittingDispute ? (
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <Spinner color={getPrimaryColor(client)} size="30px" />
                    </div>
                  ) : (
                    <Button
                      className='btn btn-lg'
                      style={{color: 'white', fontSize: 18, backgroundColor: getPrimaryColor(client), borderColor: getPrimaryColor(client)}}
                      onClick={() => {props.submitDispute(violation, name, email, reason, evidence1, evidence2, mobile)}}
                      disabled={!nameValid || !emailValid || !mobileValid}>SUBMIT</Button>
                  )}
                </FormGroup>
                <div style={{marginBottom: 60, textAlign: 'center'}}>
                  {props.submitDisputeFailed && (
                    <span style={{color: 'red'}}>Sorry, your dispute could not be sent. Please try again</span>
                  )} 
                </div>
              </Form>
            )}
          </div>
        ) : (
          <div style={{height: height - 150, overflowX: 'hidden', paddingBottom: 50, textAlign: 'center', paddingTop: 24}}>
            <span>Thank you, we have received your information and will review it.</span>
          </div>
        )
      )}
      {detailsTab === 'pay' && (
        <Row style={{marginTop: 12}}>
          <Col style={{paddingLeft: 16, paddingRight: 16}}>
            {violation.violations.map(item => (
              <Row style={{marginTop: 4}}>
                <Col xs={8}><span>{item.name}</span></Col>
                <Col xs={4} style={{textAlign: 'right'}}><span>${parseFloat(item.amount).toFixed(2)}</span></Col>
              </Row>
            ))}
            {applyEarlyBird && (
              <Row style={{marginTop: 4}}>
                <Col xs={8}><span>Prompt payment discount</span></Col>
                <Col xs={4} style={{textAlign: 'right'}}><span>-${parseFloat(parseFloat(violation.violations_total) - parseFloat(violation.early_bird_total)).toFixed(2)}</span></Col>
              </Row>
            )}
            {applyPenalty && !violation?.dispute?.adjusted_discount && (
              <Row style={{marginTop: 4}}>
                <Col xs={8}><span>Late payment penalty</span></Col>
                <Col xs={4} style={{textAlign: 'right'}}><span>${parseFloat(parseFloat(violation.penalty_total) - parseFloat(violation.violations_total)).toFixed(2)}</span></Col>
              </Row>
            )}
            {!!violation.dispute?.adjusted_discount && (
              <Row style={{marginTop: 4}}>
                <Col xs={8}><span>Adjusted discount</span></Col>
                <Col xs={4} style={{textAlign: 'right'}}><span>-${parseFloat(violation.dispute.adjusted_discount).toFixed(2)}</span></Col>
              </Row>
            )}
            {getServiceFee() > 0 && (
              <Row style={{marginTop: 4}}>
                <Col xs={8}><span>Service fee</span></Col>
                <Col xs={4} style={{textAlign: 'right'}}><span>${parseFloat(getServiceFee()).toFixed(2)}</span></Col>
              </Row>
            )}
            <Row style={{marginTop: 8}}>
              <Col xs={8}><span style={{fontWeight: 'bold'}}>Total due</span></Col>
              <Col xs={4} style={{textAlign: 'right'}}><span style={{fontWeight: 'bold'}}>${parseFloat(getTotalToPay() + parseFloat(getServiceFee())).toFixed(2)}</span></Col>
            </Row>
            <Row style={{marginTop: 8}}>
              {!!processing || locationId === null ? (
                <div style={{
                  width: '100%',
                  height: 200,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Spinner color={getPrimaryColor(client)} size="30px" />
                  <div><span>Processing payment, please wait...</span></div>
                </div>
              ) : (
                <PaymentForm
                  applicationId={applicationId}
                  locationId={locationId}
                  createPaymentRequest={() => paymentRequestOptions}
                  methodsSupported={{applePay: true, googlePay: true, card: true}}
                  cardTokenizeResponseReceived={(tokenResult) => {setProcessing(true); props.onPayment(violation, tokenResult, parseFloat(getTotalToPay() + parseFloat(getServiceFee())), name, email, parseFloat(getServiceFee()))}}
                >
                  <ApplePay />
                  <div style={{height: 16}}></div>
                  <GooglePay />
                  <Form style={{marginTop: 12, marginBottom: 12}}>
                    <FormGroup style={{marginBottom: 8}}>
                      <input type='text' className={nameTouched ? nameValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='name' onBlur={() => setNameTouched(true)} placeholder='Enter your full name *' value={name} onChange={(event) => setName(event.target.value)}/>
                      {!nameValid && nameTouched && (
                        <div className="invalid-feedback">
                          Please enter your name.
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup style={{marginBottom: 8}}>
                      <input type='email' className={emailTouched ? emailValid ? 'form-control is-valid' : 'form-control is-invalid' : 'form-control'} id='email' onBlur={() => setEmailTouched(true)} placeholder='Enter your email address *' value={email} onChange={(event) => setEmail(event.target.value)}/>
                      {!emailValid && emailTouched && (
                        <div className="invalid-feedback">
                          Please enter a valid email address.
                        </div>
                      )}
                    </FormGroup>
                  </Form>
                  <CreditCard
                    includeInputLabels={true}
                    buttonProps={{
                      isLoading: !(emailValid && nameValid),
                    }}
                  />
                </PaymentForm>
              )}
              
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
  
}

export default ViolationDetails;
